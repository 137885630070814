// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Volumes/MacintoshHD/git-projects/gatsby-landing/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Volumes/MacintoshHD/git-projects/gatsby-landing/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moscow-js": () => import("/Volumes/MacintoshHD/git-projects/gatsby-landing/src/pages/moscow.js" /* webpackChunkName: "component---src-pages-moscow-js" */),
  "component---src-pages-paris-js": () => import("/Volumes/MacintoshHD/git-projects/gatsby-landing/src/pages/paris.js" /* webpackChunkName: "component---src-pages-paris-js" */),
  "component---src-pages-prague-js": () => import("/Volumes/MacintoshHD/git-projects/gatsby-landing/src/pages/prague.js" /* webpackChunkName: "component---src-pages-prague-js" */),
  "component---src-pages-saint-petersburg-js": () => import("/Volumes/MacintoshHD/git-projects/gatsby-landing/src/pages/saint-petersburg.js" /* webpackChunkName: "component---src-pages-saint-petersburg-js" */),
  "component---src-pages-tbilisi-js": () => import("/Volumes/MacintoshHD/git-projects/gatsby-landing/src/pages/tbilisi.js" /* webpackChunkName: "component---src-pages-tbilisi-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Volumes/MacintoshHD/git-projects/gatsby-landing/.cache/data.json")

