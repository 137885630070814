/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import WebFont from 'webfontloader'
import './src/components/var.css'
import './src/components/mixins.css'

import isTouch from './src/utils/isTouch'

const setBodyClasses = () => {
  let extra = [isTouch() ? 'touch' : 'no-touch'].filter(Boolean)

  document.body.classList.add(...extra)
}

WebFont.load({
  google: {
    families: ['Open Sans:400,700'],
  },
})

setBodyClasses()
